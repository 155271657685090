<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('pages.manager.agentAndSaleManagerListing')" :onSearch="onSearch">               
                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">
                                        {{ $t("pages.manager.salesManager") }}
                                    </label>
                                    <div class="col-sm-9">
                                        <multiselect :options="saleManagerList"
                                                     v-model="selectedSaleManager"
                                                     :loading="isLoadingSaleManager"
                                                     :searchable="true"
                                                     :close-on-select="true"
                                                     :clear-on-select="false"
                                                     :multiple="true"
                                                     :placeholder="$t('common.typetosearch')"
                                                     :noOptionsText="$t('common.theListIsEmpty')"
                                                     :reset-after="false"
                                                     :local-search="false"
                                                     :internal-search="false"
                                                     @search-change="asyncFindSaleManager"
                                                     id="ajax"
                                                     label="name"
                                                     track-by="id">
                                        </multiselect>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="6">
                                <div role="group" class="form-group form-row">
                                    <label class="col-sm-3 col-form-label">{{ $t('pages.dashboard.agents') }}</label>
                                    <div class="col-sm-9">
                                        <multiselect :options="agentList"
                                                     v-model="selectedAgents"
                                                     :loading="isLoadingAgents"
                                                     :searchable="true"
                                                     :close-on-select="true"
                                                     :clear-on-select="false"
                                                     :multiple="true"
                                                     :placeholder="$t('common.typetosearch')"
                                                     :noOptionsText="$t('common.theListIsEmpty')"
                                                     :reset-after="false"
                                                     :local-search="false"
                                                     @search-change="asyncFindAgency"
                                                     :internal-search="false"
                                                     id="ajax"
                                                     label="name"
                                                     track-by="id">
                                        </multiselect>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                    </CForm>
                </template>
                <template v-slot:list>
                    <CDataTable :items="agentOfSaleManagerList"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoading"
                                pagination
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                :items-per-page-select="{
                                label : $t('common.recordsperpage'),
                                values : [10,20,50,100]
                                }">
                        <template #footer v-if="agentOfSaleManagerList != null && agentOfSaleManagerList.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="6">
                                        <span>{{ $t('common.totalRecords') }}: </span><span style="padding-left:5px;color:crimson;font-weight:bold">{{ agentOfSaleManagerList == null ? 0: agentOfSaleManagerList.length }}</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>                                            
                    </CDataTable>                  
                </template>
            </ListForm>           
        </CCol>
    </CRow>
</template>

<script>   
    import ListForm from '@/components/ListForm.vue'
    import { mapActions, mapState } from 'vuex'
    import i18n from '@/plugins/i18n'

    export default {
        name: 'agentandsalemanager',
        data() {
            return {
                agentList: [],
                selectedAgents: null,
                isLoadingAgents: false,               
                saleManagerList: [],
                selectedSaleManager: null,
                isLoadingSaleManager: false,
                itemsPerPage: 10,               
                saleId: 0,
                agentId: 0,
                fields: [
                    { key: 'salesName', label: i18n.t('pages.manager.salesName') },
                    { key: 'agentName', label: i18n.t('pages.agent.agentName') },
                    { key: 'level', label: i18n.t('pages.manager.level') }
                ]
            };
        },
        components: { ListForm },
        computed: {          
            ...mapState('saleandagent', ['isLoading', 'agentOfSaleManagerList', 'totalPages', 'pageIndex'])
        },
        methods: {
            ...mapActions('saleandagent', ['searchAgentOfSaleManager']),
            onSearch() {
                let _saleIds = [], _agentIds = [];
                
                if (this.selectedSaleManager != null && this.selectedSaleManager.length > 0)
                    _saleIds = this.selectedSaleManager.map(item => item.id);

                if (this.selectedAgents != null && this.selectedAgents.length > 0)
                    _agentIds = this.selectedAgents.map(item => item.id);
                
                this.searchAgentOfSaleManager({ pageNumber: 0, saleIds: _saleIds, agentIds: _agentIds });
            },                       
            onItemsPerPageChange(item) {
                localStorage.setItem('agentAnSaleManagerItemsPerPage', item)
            },

            async asyncFindSaleManager(query, id) {
                if (query.length > 1) {
                    this.isLoadingSaleManager = true;
                    var result = await this.$salesmanService.searchByName(query);
                    this.isLoadingSaleManager = false;
                    this.saleManagerList = result;
                }
            },
            async asyncFindAgency(query, id) {
                if (query.length > 2) {
                    this.isLoadingAgents = true;
                    var result = await this.$agentService.searchByName(query);
                    this.isLoadingAgents = false;
                    this.agentList = result;
                }
            },
        },
        created() {
            this.onSearch();

            let agentAnSaleManagerItemsPerPage = parseInt(localStorage.getItem('agentAnSaleManagerItemsPerPage'));
            if (isNaN(agentAnSaleManagerItemsPerPage))
                agentAnSaleManagerItemsPerPage = 10;
            this.itemsPerPage = agentAnSaleManagerItemsPerPage;
        }     
    }
</script>